
import { defineComponent, onMounted, reactive } from "vue";
import { addOgp, uploadImage, update } from "@/components/firebaseOperations";
import store from "@/store";
import { useRouter } from "vue-router";
import html2canvas from "html2canvas";
import Compressor from "compressorjs";
import constants from "@/components/constants";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    tagImages: {
      type: Array,
      required: true,
    },
    pathParamId: {
      type: String,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const state = reactive({
      title: props.title,
      tagImages: props.tagImages,
      pathParamId: props.pathParamId,
      isAdd: props.isAdd,
    });

    const createJpegFile4Base64 = (base64: string, name: string) => {
      // base64のデコード
      let bin = atob(base64.replace(/^.*,/, ""));
      // バイナリデータ化
      let buffer = new Uint8Array(bin.length);
      for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      // ファイルオブジェクト生成(この例ではjpegファイル)
      return new File([buffer.buffer], name, { type: "image/jpeg" });
    };

    const fileUpload = async (base64: any): Promise<void> => {
      const payload: Compressor.Options = {
        quality: 0.75,
        mimeType: "image/jpeg",
        async success(blob: Blob) {
          const reader = new FileReader();
          (reader.onloadend = async () => {
            const result = reader.result;
            if (result instanceof ArrayBuffer || result === null) return;
            const uploadImageUrl = await uploadImage(
              result,
              "articleOgps",
              `${state.pathParamId}.jpeg`
            );

            if (state.isAdd) {
              await addOgp(uploadImageUrl, state.title, state.pathParamId);
            } else {
              await update(
                constants.FIREBASE_COLLECTION.OGPS,
                state.pathParamId,
                {
                  title: state.title,
                  image_url: uploadImageUrl,
                }
              );
            }
          }),
            reader.readAsDataURL(blob);
        },
        error(err: Error): void {
          console.log(err.message);
        },
      };
      const imageFile: File = createJpegFile4Base64(base64, "ogp");
      new Compressor(imageFile, payload);
    };

    onMounted(async () => {
      const ogpParent = document.getElementById("ogp-parent");
      if (ogpParent != null) {
        const canvas = await html2canvas(ogpParent, {
          width: 1200,
          height: 630,
          useCORS: true,
          scale: 1,
          onclone: function(clonedDoc: any) {
            clonedDoc.getElementById("ogp-parent").style.display = "block";
          },
        });

        fileUpload(canvas.toDataURL());
      }

      document.body.classList.remove("modal-open"); // 記事作成 or 記事更新を表すモーダルを閉じる
      router.push({
        path: `/article/${state.pathParamId}`,
      });
    });

    return {
      store,
      state,
    };
  },
});
