
import { defineComponent } from "vue";
import Header from "@/components/parts/Header.vue";
import BottomNavigation from "@/components/parts/BottomNavigation.vue";
import AddOrEditPage from "@/components/pages/AddOrEditPage.vue";

export default defineComponent({
  components: {
    Header,
    BottomNavigation,
    AddOrEditPage,
  },
})
