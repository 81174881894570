
import { defineComponent, onMounted, reactive } from "vue";
import DialogBase from "@/components/parts/DialogBase.vue";
import CreateArticleDialog from "@/components/parts/CreateArticleDialog.vue";
import store from "@/store";
import {
  cleansingTag,
  getFormatDate,
  getSns,
  compiledMarkdownText,
} from "@/components/aboutArticleFunc";
import {
  getMaxId,
  addDocument,
  update,
  uploadImage,
} from "@/components/firebaseOperations";
import { createSearchCandidates } from "@/components/createSearchCandidates";
import { removeImageValue } from "@/components/imageFunc";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import axios from "axios";
import constants from "@/components/constants";
import { openModal, closeModal } from "@/components/modalFunc";
import OGP from "@/components/parts/Ogp.vue";
import "vue-loaders/dist/vue-loaders.css";
import techIcons from "@/components/techIcons";
import Compressor from "compressorjs";

export default defineComponent({
  components: {
    DialogBase,
    CreateArticleDialog,
    OGP,
  },
  setup() {
    const router = useRouter();

    onBeforeRouteLeave((to, from, next) => {
      if (state.completed) {
        store.commit("setArticleToEdit", {});
        if (from.name === "EditArticle" && to.name === "AddArticle") {
          window.location.href = `${document.location.origin}${to.path}`;
        } else {
          next();
        }
      } else {
        state.transitionTo = to.path;
        state.discardModal = true;
        next(false);
      }
    });

    const state = reactive({
      article: {
        tag_names: [] as string[],
        tag_images: [] as { [key: string]: string }[],
        exact_tags: [] as string[],
        shorten_image_urls: [] as { [key: string]: string }[],
        markdown_text: "",
        main_code: "",
        title: "",
        search_candidates: {},
      } as { [key: string]: any },
      previewMainCode: "",
      isPreview: false,
      sns: [] as { [key: string]: string }[],
      cleansingDate: "",
      addTagText: "",
      discardModal: false,
      isAdd: false,
      creatingOrUpdating: "記事作成中",
      errorMessage: {
        title: "",
        tag: "",
        mainCode: "",
      },
      ogpModal: false,
      completed: false,
      transitionTo: "",
      titleMaxLength: 150,
    });

    // タブを用いたインデント, アンインデント
    const insertTab = (tag: string) => {
      const textarea = document.getElementById(tag) as HTMLTextAreaElement;

      textarea.addEventListener("keydown", function(ev: any) {
        // タブキーが押されたときだけ
        if (ev.keyCode == 9) {
          let isShift = ev.shiftKey;
          let elm = ev.target;
          let txt = elm.value;
          let slct = { left: elm.selectionStart, right: elm.selectionEnd };

          if (slct.left == slct.right && !isShift) {
            elm.value =
              txt.substr(0, slct.left) +
              "\t" +
              txt.substr(slct.left, txt.length);
            slct.left++;
            slct.right++;
          } else {
            let lineStart = txt.substr(0, slct.left).split("\n").length - 1;
            let lineEnd = txt.substr(0, slct.right).split("\n").length - 1;
            let lines = txt.split("\n");
            for (let i = lineStart; i <= lineEnd; i++) {
              if (!isShift) {
                lines[i] = "\t" + lines[i];
                if (i == lineStart) slct.left++;
                slct.right++;
              } else if (lines[i].substr(0, 1) == "\t") {
                lines[i] = lines[i].substr(1);
                if (i == lineStart) slct.left--;
                slct.right--;
              }
            }
            elm.value = lines.join("\n");
          }

          elm.setSelectionRange(slct.left, slct.right);
          // フォーカス移動が発生しないように
          ev.preventDefault();
          return false;
        }
      });
    };

    /// 短縮URLを取得
    /// @param {string} targetUrl 短縮URL
    /// @return {string} - 短縮URL
    const getShortenUrl = async (targetUrl: string) => {
      try {
        const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${
          process.env.NODE_ENV === "production"
            ? "AIzaSyAsY0m-PL6FjyngTTLH7Z4j4hhqiduCnoc"
            : "AIzaSyAbly-yvlgoA1d9v_s781pB_8EQ98Y7e6U"
        }`;
        const params = {
          dynamicLinkInfo: {
            domainUriPrefix: `${
              process.env.NODE_ENV === "production"
                ? "https://dogears.page.link"
                : "https://dogearsdev.page.link"
            }`,
            link: targetUrl,
          },
          suffix: {
            option: "SHORT",
          },
        };

        // POSTでリクエスト
        const res: any = await axios.post(url, params);

        // shortLinkが短縮URL
        return res.data.shortLink;
      } catch (error) {
        // エラーが発生したら、短縮前のURLを返す
        return targetUrl;
      }
    };

    const fileUpload = async (e: any): Promise<void> => {
      const payload: Compressor.Options = {
        quality: 0.75,
        mimeType: "image/jpeg",
        async success(blob: Blob) {
          const reader = new FileReader();
          (reader.onloadend = async () => {
            const result = reader.result;
            if (result instanceof ArrayBuffer || result === null) return;
            const uploadImageUrl = await uploadImage(
              result,
              "imagesInArticle",
              `${state.article.path_param_id}-${new Date().getTime()}.jpeg`
            );
            const shortenUrl = await getShortenUrl(uploadImageUrl);
            state.article.shorten_image_urls.push({
              long: uploadImageUrl,
              short: shortenUrl,
            }); // 元URLと短縮URLとを紐づけたMapを追加

            insertImageText(`![](${shortenUrl})`);
          }),
            reader.readAsDataURL(blob);
        },
        error(err: Error): void {
          console.log(err.message);
        },
      };
      const imageFile: File = e.target.files[0];
      new Compressor(imageFile, payload);
    };

    /// 画像をmarkdownに追加する
    /// @param {string} text - markdownで画像を表示するための文字列
    const insertImageText = (text: string) => {
      const textarea: any = document.getElementById("add-markdown-textarea");

      let sentence = textarea.value;
      let len = sentence.length;
      let pos = textarea.selectionStart;

      let before = sentence.substr(0, pos);
      let after = sentence.substr(pos, len);

      sentence = before + text + after;

      state.article.markdown_text = sentence; // 選択中の場合は選択箇所に、選択していない場合は文末に画像に関する文字列を追加
    };

    /// タグを追加する
    /// @param {any} e - element
    const addTag = (e: any) => {
      if (e.keyCode === 13) {
        if (
          state.article.tag_names.length + state.article.tag_images.length <
          5
        ) {
          state.errorMessage.tag = "";

          let inputText = state.addTagText;
          inputText = cleansingTag(inputText);
          if (inputText != "") {
            if (inputText.length <= 30) {
              let addAvailableTagIcon = true;
              let addAvailableTagName = true;
              const techIconsKey = inputText.replace(/_/g, "");
              const icon = (techIcons.icons as any)[techIconsKey];

              if (icon != undefined) {
                // 該当するtechIconがあった場合
                state.article.tag_images.forEach(
                  (tag: { [key: string]: string }) => {
                    if (tag.path === icon.path) {
                      addAvailableTagIcon = false;
                    }
                  }
                );

                if (addAvailableTagIcon) {
                  state.article.tag_images.push({
                    name: techIconsKey,
                    path: icon.path,
                    exact_name: icon.exactName,
                  });
                } else {
                  state.errorMessage.tag = "既にiconとして追加済みです。";
                }
              } else {
                // 該当するtechIconが無かった場合(タグを追加する場合)
                state.article.tag_names.forEach((tag: string) => {
                  if (tag === inputText) {
                    addAvailableTagName = false;
                  }
                });

                if (addAvailableTagName) {
                  state.article.tag_names.push(inputText);
                } else {
                  state.errorMessage.tag = "既にtagとして追加済みです。";
                }
              }
            } else {
              state.errorMessage.tag = "タグは30文字以内で入力してください";
            }
          }
        } else {
          state.errorMessage.tag = "最大5個追加可能です";
        }
        state.addTagText = "";
        e.target.parentElement.firstElementChild.focus();
      }
    };

    /// プレビュー
    const preview = async () => {
      state.article.shorten_image_urls.forEach(
        (image: { [key: string]: string }) => {
          if (state.isPreview) {
            state.article.markdown_text = state.article.markdown_text
              .split(image.long)
              .join(image.short); // markdown中の短縮URLを元のURLに戻す
          } else {
            state.article.markdown_text = state.article.markdown_text
              .split(image.short)
              .join(image.long); // markdown中の元URLを短縮URLに戻す
          }
        }
      );

      if (!state.isPreview) {
        state.previewMainCode = state.article.main_code;
      }

      state.isPreview = !state.isPreview;

      if (!state.isPreview) {
        await increaseOrDecreaseHeight("add-title-textarea");
        await increaseOrDecreaseHeight("add-main-code-textarea");
        await increaseOrDecreaseHeight("add-markdown-textarea");
        increaseOrDecreaseHeightEventListener("add-title-textarea");
      }
    };

    /// 完了処理
    /// @param {string} type - タイプ(add or edit)
    const complete = async (type: string) => {
      state.errorMessage.tag = "";
      state.article.exact_tags = [];

      const now = new Date();
      if (state.article.title === "") {
        state.errorMessage.title = "タイトルを入力してください";
      } else if (state.article.title.length > state.titleMaxLength) {
        state.errorMessage.title = `タイトルは${state.titleMaxLength}文字以内で入力してください`;
      } else {
        state.errorMessage.title = "";
      }

      if (state.article.main_code === "") {
        state.errorMessage.mainCode = "main codeを入力してください";
      } else {
        state.errorMessage.mainCode = "";
      }

      if (state.errorMessage.title == "" && state.errorMessage.mainCode == "") {
        state.article.tag_names.forEach((text: string) => {
          state.article.exact_tags.push(text);
        });

        state.article.tag_images.forEach((icon: { [key: string]: string }) => {
          state.article.exact_tags.push(icon.exact_name);
        });

        if (
          state.article.tag_images.length === 0 &&
          state.article.tag_names.length === 0
        ) {
          state.article.tag_images.push({
            name: "code",
            path: "code.png",
            exact_name: "code",
          });
          state.article.exact_tags = ["code"];
        }

        const correctShortenImageUrls: { [key: string]: string }[] = [];

        state.article.shorten_image_urls.forEach(
          (image: { [key: string]: string }) => {
            if (state.article.markdown_text.indexOf(image.short) !== -1) {
              // 画像が追加されていた場合
              correctShortenImageUrls.push(image);
              state.article.markdown_text = state.article.markdown_text
                .split(image.short)
                .join(image.long); // markdown内の短縮URLを、元のURLに戻す
            }
          }
        );

        state.article.search_candidates = createSearchCandidates(
          state.article.title
        );

        const doc: { [key: string]: any } = {
          main_code: state.article.main_code,
          markdown_text: state.article.markdown_text,
          tag_names: state.article.tag_names,
          tag_images: state.article.tag_images,
          exact_tags: state.article.exact_tags,
          title: state.article.title,
          search_candidates: state.article.search_candidates,
          shorten_image_urls: correctShortenImageUrls,
          updated_at: now,
        };

        if (state.isAdd) {
          doc["id"] =
            (await getMaxId(constants.FIREBASE_COLLECTION.ARTICLES)) + 1;
          doc["is_open"] = type === "not-publish" ? false : true;
          doc["is_enable"] = true;
          doc["path_param_id"] = state.article.path_param_id;
          doc["created_at"] = now;
          doc["thanks"] = 0;
          doc["users_id"] = store.state.myUser.id;

          await addDocument(constants.FIREBASE_COLLECTION.ARTICLES, doc);
        } else {
          state.creatingOrUpdating = "記事更新中";
          await update(
            constants.FIREBASE_COLLECTION.ARTICLES,
            state.article.docId,
            doc
          );
        }

        state.completed = true;
        openModal(state, constants.MODAL.OGP);
      }
    };

    /// 廃棄
    const discard = () => {
      state.completed = true;

      if (state.transitionTo.length != 0) {
        router.push({ path: state.transitionTo });
      } else if (state.isAdd) {
        router.push({ path: "/" });
      } else {
        router.push({
          path: `/article/${state.article.path_param_id}`,
        });
      }
    };

    /// タグを削除
    /// @param {string} type - タイプ(techIcon or tag)
    /// @param {string} name - タグ名
    /// @param {number} index - 位置を表すindex
    const deleteTag = (type: string, name: string, index: number) => {
      if (type === "image") {
        state.article.tag_images.splice(index, 1);
      } else {
        state.article.tag_names.splice(index, 1);
      }
    };

    const _sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const increaseOrDecreaseHeight = async (id: string) => {
      let textarea;
      do {
        textarea = document.getElementById(id);

        if (textarea !== null) {
          textarea.style.height = "auto";

          //textareaの高さに入力内容の高さを設定
          textarea.style.height = textarea.scrollHeight + "px";

          if (textarea.style.height === "0px") {
            await _sleep(100);
            continue;
          }
        } else {
          await _sleep(100);
        }
      } while (textarea === null);
    };

    const increaseOrDecreaseHeightEventListener = (id: string) => {
      let textarea: HTMLElement | null;
      do {
        textarea = document.getElementById(id);
        if (textarea !== null) {
          //textareaのinputイベント
          textarea.addEventListener("input", () => {
            increaseOrDecreaseHeight(id);
          });
        }
      } while (textarea === null);
    };

    const close = () => {
      state.discardModal = true;
    };

    const cancel = () => {
      state.transitionTo = "";
      closeModal(state, constants.MODAL.DISCARD);
    };

    onMounted(async () => {
      //textareaの要素を取得
      increaseOrDecreaseHeightEventListener("add-title-textarea");
      increaseOrDecreaseHeightEventListener("add-main-code-textarea");
      increaseOrDecreaseHeightEventListener("add-markdown-textarea");
      await increaseOrDecreaseHeight("add-title-textarea");
      await increaseOrDecreaseHeight("add-main-code-textarea");
      await increaseOrDecreaseHeight("add-markdown-textarea");

      document.getElementById("add-title-textarea")?.focus();
      insertTab("add-main-code-textarea");
      insertTab("add-markdown-textarea");
    });

    if (router.currentRoute.value.name === "AddArticle") {
      state.isAdd = true;
      const sha256 = async (text: string) => {
        const uint8 = new TextEncoder().encode(text);
        const digest = await crypto.subtle.digest("SHA-256", uint8);
        return Array.from(new Uint8Array(digest))
          .map((v) => v.toString(16).padStart(2, "0"))
          .join("");
      }; // 記事のためのpath_param_idを生成するための関数

      sha256(`dog-ears1-${store.state.myUser.id}-${new Date().getTime()}`).then(
        (hash) => (state.article.path_param_id = hash.slice(0, 15))
      ); // path_param_idの設定

      state.cleansingDate = getFormatDate(new Date().getTime() / 1000);
    } else {
      state.article = store.state.articleToEdit;

      state.article.shorten_image_urls.forEach(
        (image: { [key: string]: string }) => {
          state.article.markdown_text = state.article.markdown_text
            .split(image.long)
            .join(image.short);
        }
      );

      state.cleansingDate = getFormatDate(state.article.created_at.seconds);
    }

    state.sns = getSns(false, store.state.myUser);

    return {
      removeImageValue,
      compiledMarkdownText,
      constants,
      state,
      store,
      addTag,
      preview,
      complete,
      discard,
      deleteTag,
      insertImageText,
      fileUpload,
      closeModal,
      close,
      cancel,
    };
  },
});
